export enum ClickCtaUxSource {
  Top = 'top_button',
  TopRightHeader = 'top_right_header_button',
  CreationSelection = 'creation_selection_button',
  Bottom = 'bottom_button',
  TemplateSelection = 'template_selection_button',
  VideoWallTemplateSelection = 'video_wall_template_selection_button',
  WorkflowBlankCanvasCardButton = 'workflow_blank_canvas_card_button',
  WorkflowBlankCanvasCardText = 'workflow_blank_canvas_card_text',
  WorkflowTemplatesCardButton = 'workflow_templates_card_button',
  WorkflowTemplatesCardText = 'workflow_templates_card_text',
  WorkflowTextToVideoCardButton = 'workflow_text_to_video_card_button',
  WorkflowTextToVideoCardText = 'workflow_text_to_video_card_text',
  WorkflowCard = 'workflow_card',
  FreePlan = 'free_plan_button',
  BusinessPlan = 'business_plan_button',
  UnlimitedPlan = 'unlimited_plan_button',
  FeaturesSelection = 'feature_selection_button',
  LetsConnect = 'lets_connect',
  TemplateSlider = 'template_slider',
  TemplateGrid = 'template_grid',
  VideoWallTemplateGrid = 'videowall_template_grid',
  TutorialSmallCard = 'tutorial_small_card',
  TutorialLargeCard = 'tutorial_large_card',
  WebinarLargeCard = 'webinar_large_card',
  SocialHandleSmallCard = 'social_handle_small_card',
  CompareAllButton = 'compare_all_button',
  ClickSupportIcon = 'click_support_icon',
  TopHeader = 'top_header_button',
}

export enum ClickCtaType {
  ClickSignup = 'click_signup',
  ClickLogin = 'click_login',
  ClickOpenTemplate = 'click_open_template',
  ClickTutorial = 'click_tutorial',
  ClickSocialHandle = 'click_social_handle',
  ClickWebinar = 'click_Webinar',
  ClickCompareAll = 'click_compare_all',
  ClickSupport = 'click_support',
  ClickJoinAi = 'click_join_ai',
  ClickChatGPTBanner = 'click_chat_gpt_banner',
}

export enum HomepageExperiment {
  Treatment = 'treatment',
  Control = 'control',
}

export interface ClickCtaEventProperties {
  ux_source: ClickCtaUxSource;
  type: ClickCtaType;
  feature_utm: string;
  product: AnalyticsProductType;
}

export interface ClickOpenTemplateProperties {
  template_id: number;
}

export interface ExperimentViewedProperties {
  experiment: {
    homepage_revamp: HomepageExperiment;
  };
}

export interface ClickCtaAnalytics {
  event_name: 'click_cta';
  event_properties: ClickCtaEventProperties;
  user_properties?: any;
}

export interface ExperimentViewedAnalytics {
  event_name: 'experiment_viewed';
  event_properties: ExperimentViewedProperties;
  user_properties?: any;
}

export enum AnalyticsProductType {
  AI = 'AI',
  STUDIO = 'studio',
}
